<template>
  <div class="product_box cart_product_box order_product_box flex-r-c-c" style="min-height:0">
    <div class="pro_info flex-r-s-c">
      <van-image width="2.56rem" height="1.92rem" :src="myinfo.proIcon" />
      <div class="cart_pro_info">
        <div class=" pro_name van-multi-ellipsis--l2 flex-r-s-s">
          <div class="flex-r-s-c">
            <div v-if="myinfo.giveFlag === 1" class="pro_zeng">赠</div>
            {{ myinfo.proName }}
          </div>
        </div>
        <div class="pro_brand_model van-ellipsis">{{ myinfo.proBrand }} | {{ myinfo.proModel }}</div>
        <!-- <div class="pro_tags">1</div> -->
        <div class="pro_bottom flex-r-sb-c">
          <div class="pro_money">￥<span
            style="font-size: 0.49rem;"
          >{{ myinfo.showPrice === 0? myinfo.proPrice :'详询客服' }}</span></div>
          <div v-if="type === '2'" class="pro_num_change">
            <van-stepper v-model="myinfo.proNumber" :step="myinfo.minQuantity" :min="myinfo.minQuantity" integer
                         :max="mycanBuyNum" input-width="1rem" button-size="0.5rem" @change="changeNum"
            />
          </div>
          <div v-else-if="type === '4'" class="pro_num_change_no" style="font-size: 0.39rem;">x
            {{ myinfo.proNumber || myinfo.buyNumber }}
          </div>
          <div v-else class="pro_num_change_no" style="font-size: 0.39rem;">x {{ myinfo.proNumber || myinfo.buyNumber }}
          </div>
          <van-button v-if="parseInt(kind) === 2 && parseInt(myinfo.giveFlag) === 0" plain type="primary" round
                      size="mini" color="#666" @click.stop="addCart"
          >
            加入购物车</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { shoppingCart_addUnion } from '@/api/cart'
export default {
  props: {
    info: { type: Object, default: () => { } },
    type: { type: String, default: '' },
    kind: { type: String, default: '1' },
    cannum: { type: Number, default: null }
  },
  data() {
    return {
      myinfo: this.info,
      mycanBuyNum: this.cannum === -1 ? 9999 : this.cannum
    }
  },
  methods: {
    // 修改数量
    changeNum(val) {
      var minQuantity = parseInt(val) % parseInt(this.myinfo.minQuantity) === 0
      console.log(minQuantity)
      if (parseInt(val) > 0 && minQuantity) { this.$emit('changeNum', val) } else {
        this.myinfo.proNumber = 0
      }
      this.$forceUpdate()
    },
    // 加入购物车
    addCart() {
      const products = { proNumber: this.info.minQuantity, productId: this.info.productId }
      shoppingCart_addUnion({ companyId: '1000', products: [products] }).then(res => {
        this.$toast({ message: '加入成功！', duration: 1 * 1000 })
      })
    }
  }
}
</script>
